export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~5],
		"/about-us": [6],
		"/cart": [~7,[2]],
		"/cart/checkout": [~8,[2,3]],
		"/cart/checkout/complete-purchase": [~10],
		"/cart/checkout/[slug]": [~9,[2,3]],
		"/cart/reorder": [~11,[2]],
		"/collections": [~12],
		"/collections/cream-gel": [~14],
		"/collections/[slug]": [~13],
		"/color-dip": [~15],
		"/contact": [16],
		"/customers/account": [~17],
		"/customers/account/order_id/[[slug]]": [~18],
		"/customers/sended-email": [19],
		"/customers/signout": [~20],
		"/deals": [~21],
		"/essentials": [~22],
		"/faq-s": [23],
		"/forget-password": [24],
		"/fortify-protect": [~25],
		"/how-to": [~26,[4]],
		"/how-to/[slug]": [~27,[4]],
		"/kits": [~28],
		"/led-gel-polish": [~29],
		"/led-lights": [~30],
		"/privacy-policy": [31],
		"/products/[...slug]": [~32],
		"/shipping-returns": [33],
		"/signup": [34],
		"/social": [35],
		"/soft-gel": [~36],
		"/video": [37],
		"/warrantyregistration/index": [38],
		"/warrantyregistration/index/success": [39]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';